import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class PGIService {
  token: string;
  httpOptions: any = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*', // Required for CORS support to work
      Authorization:
        'n7m0c2b9z6C9u3w9eliufhfnjkdnbcaakjdhgasdhflhaigkladsglghakoiruegkajsnbnvvjvbv',
    },
  };
  constructor(public http: HttpClient, public requestService: RequestService) { }

  async getPGIOverview(postData: any): Promise<any> {
    console.log(postData);
    let agencyID = this.requestService.currentUser?.mirror_1__1;
    postData = {
      agency: agencyID, // 'y7q56QsPOb98kC_yCtrmXN5DR4_6-z_wH4xqykpxaYY1',
      // agency: 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
      // lob: 'Homeowners',
      // state: 'Colorado',
      // productline: 'Personal',
      // agent: 'Osmaylin Henriquez',
      // carrier: 'Allied',
      // test: false,
    };
    return await this.http
      .post<any>(
        environment.monday_api_url + '/api/pgi',
        postData,
        this.httpOptions
      )
      .toPromise();
  }
  async getPGIOverviewFilter(agencyId: string = this.requestService.currentUser?.mirror_1__1, lob: any = [], state: any = [], productline: any = [], agent: any = [], carrier: any = []): Promise<any> {
    let postData: any = {
      agency: agencyId, // 'y7q56QsPOb98kC_yCtrmXN5DR4_6-z_wH4xqykpxaYY1',
      // agency: 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
      // lob: 'Homeowners',
      // state: 'Colorado',
      // productline: 'Personal',
      // agent: 'Osmaylin Henriquez',
      // carrier: 'Allied',
      // test: false,
    };

    if (lob?.length) {
      postData.lob = lob;
    }

    if (state?.length) {
      postData.state = state;
    }

    if (productline?.length) {
      postData.productline = productline;
    }

    if (agent?.length) {
      postData.agent = agent;
    }

    if (carrier?.length) {
      postData.carrier = carrier;
    }

    return await this.http
      .post<any>(
        environment.monday_api_url + '/api/pgifilter',
        postData,
        this.httpOptions
      )
      .toPromise();
  }

  async getPGITargetedOpportunities(): Promise<any> {
    let agencyID = this.requestService.currentUser?.mirror_1__1;
    let postData = {
      agency: agencyID, // 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
    };
    return await this.http
      .post<any>(
        environment.monday_api_url + '/api/opportunitiesAll',
        postData,
        this.httpOptions
      )
      .toPromise();
  }

  async getPGITelematicsDetails(
    pageIndex: number,
    pageSize: number,
    telematics: string
  ): Promise<any> {
    let agencyID = this.requestService.currentUser?.mirror_1__1;
    let postData = {
      agency: agencyID, // 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
      page_num: pageIndex,
      page_size: pageSize,
      type: 'telematics',
      telematics: telematics,
    };
    return await this.http
      .post<any>(
        environment.monday_api_url + '/api/opportunitiesDetails',
        postData,
        this.httpOptions
      )
      .toPromise();
  }

  // getFilter() {
  //   return this.http.get<any[]>('assets/data/dashboard.json');
  // }

  // getAgencyList() {
  //   return this.http.get<any[]>('assets/data/agencies.json');
  // }
  // getAgentList() {
  //   return this.http.get<any[]>('assets/data/agents.json');
  // }
  // getlobsList() {
  //   return this.http.get<any[]>('assets/data/lobs.json');
  // }
  // getStateList() {
  //   return this.http.get<any[]>('assets/data/states.json');
  // }
  // getProductList() {
  //   return this.http.get<any[]>('assets/data/products.json');
  // }
  // getCarrierList() {
  //   return this.http.get<any[]>('assets/data/carriers.json');
  // }
  getVideoList() {
    return this.http.get<any[]>('assets/data/videos.json');
  }
  getNotifications() {
    return this.http.get<any[]>('assets/data/previousNotifications.json');
  }
  getPgi() {
    return this.http.get<any[]>('assets/data/pgi.json');
  }
  getOpportunities() {
    return this.http.get<any[]>('assets/data/opportunitiesDetails.json');
  }
}
